<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row">
          <h1>Dispatch Management</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-if="getGlobalValue('productSerialShowOnDispatch')">
        <v-col>
          <v-text-field class="mb-2" hide-details style="width: 500px;" dense outlined :label="`Search ${serialNaming.plural}`" v-model="serialSearch"></v-text-field>
          <v-btn color=info small @click="searchSerial" :disabled="!serialSearch || !serialSearch.trim()">Search</v-btn>
          <span v-if="serialSearchResults && serialSearchResults.length > 0" class="d-flex flex-column">
                <b>Found {{ serialSearchResults.reduce((a,b) => a+b.orders.length, 0) }} {{ serialSearchResults.reduce((a,b) => a+b.orders.length, 0) > 1 ? "documents" : "document" }} from {{ serialSearchResults.length }} {{ serialSearchResults.length > 1 ? "products" : "product" }} containing the term "{{ serialSearch }}"</b>
                <span v-for="(result, index) in serialSearchResults" :key="index">
                  <hr class="mt-2">
                  <b>Product #{{result.Product.id}} | {{result.Product.name}}</b>
                  <span class="d-flex flex-row flex-wrap">
                    <v-btn v-for="(order, o) of result.orders" :key="o" class="mr-2 mt-1" small @click="openSerialDialog({id: order.orderId})">Order #{{ order.orderId }}</v-btn>
                  </span>
                </span>
              </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h2>Orders</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="">
              <span class="d-flex flex-row align-center">
                <span class="mr-5">
                  <v-select label="Status" @change="options.page=1" v-model="options.status" :items="statuses" outlined dense hide-details/>
                </span>
                <v-checkbox
                    v-model="allBranchesSelected"
                    label="All"
                    :value="0"
                    @click="updateBranchFilter(0)"
                />
                <span class="d-flex flex-row ml-5">
                  <v-checkbox
                      v-for="(branch, i) of allBranches"
                      :key="i"
                      v-model="options.selectedBranches"
                      :label="branch.label"
                      :value="branch.value"
                      class="mr-5"
                      @click="updateBranchFilter(branch.value)"
                  ></v-checkbox>
                </span>
              </span>
              <span>
                <v-text-field hide-details label="Go To Dispatch #" dense outlined @change="goToDocument" class="mt-5" v-model="dispatchIdSearch" :loading="goToDocumentLoader"/>
              </span>
            </v-card-text>
            <v-data-table
                @click:row="rowClick"
                :headers="headers"
                :items="orders"
                :options.sync="options"
                :server-items-length="pagination.dataCount"
                :loading="pagination.loading"
                :items-per-page="15"
                class="elevation-1"
                :footer-props="{
                  itemsPerPageOptions: pagination.pageSizeOptions
                }"
            >
              <template v-slot:item.customerName="{ item }">
                <span>{{ item.Customer?(item.Customer.name?item.Customer.name:(item.Customer.firstName||item.Customer.lastName?(`${item.Customer.firstName||''} ${item.Customer.lastName||''}`):'-')):"-" }}</span>
              </template>
              <template v-slot:item.customerPhone="{ item }">
                <span>{{ item.Customer?.phone||'-' }}</span>
              </template>
              <template v-slot:item.csrUser="{ item }">
                <span>{{item.createdBy?lookupUsername(item.createdBy):'-'}}</span>
              </template>
              <template v-slot:item.address="{ item }">
                <span class="">{{[item.deliveryInfo.address.line1, item.deliveryInfo.address.line2, item.deliveryInfo.address.city, item.deliveryInfo.address.country].filter(x => x && x.trim().length>0).join(",\n")}}</span>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="utils.getDeliveryStatusColor(item.deliveryInfo.status)">{{utils.parseDeliveryStatus(item.deliveryInfo.status)}}</v-chip>
              </template>
              <template v-slot:item.sealedAt="{ item }">
                <span>{{utils.formatDate(item.sealedAt, 'short')}}</span>
              </template>
              <template v-slot:item.branchId="{ item }">
                <span>{{lookupBranch(item.branchId)}}</span>
              </template>
              <template v-slot:item.sealedBy="{ item }">
                <span>{{lookupUsername(item.sealedBy)}}</span>
              </template>
              <!-- <template v-slot:item.actions="{ item }">
                <span>
                  <v-btn class="mr-1" x-small fab color="warning" v-if="!item.deliveryInfo.status || item.deliveryInfo.status===0" :loading="item.isLoading" @click="openSerialDialog(item)"><v-icon>mdi-barcode</v-icon></v-btn>
                  <v-btn class="mr-1" x-small fab color="success" v-if="item.deliveryInfo.status===1" @click="openSerialDialog(item)"><v-icon>mdi-package-variant-closed-check</v-icon></v-btn>
                  <v-btn class="mr-1" x-small fab color="info" v-if="item.deliveryInfo.status>=2" @click="openSerialDialog(item)"><v-icon>mdi-eye</v-icon></v-btn>
                </span>
              </template> -->
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
#existingSerials{
  border-collapse: collapse;
}
#existingSerials td, #existingSerials th{
  border: 1px solid #ff8080;
  padding: 5px;
}
#existingSerials tbody tr:hover{
  background: #f50000;
  color: white;
}
</style>
<script>
  import axios from 'axios';
import { mapGetters } from "vuex";
import utils from "../../plugins/helpers";
  export default {
    data () {
      return {
        utils: utils,

        serialSearch: '',
        serialSearchResults: [],

        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        tableTitle: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Status', value: 'status' },
          { text: 'Date Sealed', value: 'sealedAt' },
          { text: 'Customer', value: 'customerName', sortable: false },
          { text: 'Phone', value: 'customerPhone', sortable: false },
          { text: 'Address', value: 'address', sortable: false },
          { text: 'CSR', value: 'csrUser', sortable: false },
          { text: 'Branch', value: 'branchId', sortable: false },
          { text: '# Items', value: 'itemCount', sortable: false},
        ],
        editMode: false,
        orders: [],

        pagination: {
          loading: false,
          dataCount: 0,
          pageSizeOptions: [5, 10, 15, 30, -1]
        },
        options: {
          search: "",
          sortBy: ['id'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15,
          selectedBranches: [],
          status: null
        },
        updatingRoute: false,
        init: false,

        allBranches: [],
        allBranchesSelected: true,
        createDispatchLoading: false,

        singular: "Dispatch",
        singularLower: "dispatch",
        plural: "Dispatches",
        pluralLower: "dispatches",

        statuses: [
          {text: "All", value: null},
          {text: "New", value: 0},
          {text: "In Progress", value: 1},
          {text: "Completed", value: 2},
          {text: "Voided", value: -1},
        ],

        dispatchStatuses: [
          {text: "Voided", value: -1},
          {text: "New", value: 0},
          {text: "Scheduled", value: 1},
          {text: "Out for Delivery", value: 2},
          {text: "Attempted Delivery", value: 3},
          {text: "Delayed", value: 4},
          {text: "Delivered", value: 5},
        ],

        lineErrors: {
          productId: null,
          errors: []
        },

        dispatchIdSearch: '',
        goToDocumentLoader: false,
      }
    },
    async created(){
      try{
        //used for pagination
        let oldQuery = this.getURLQuery();
        this.options.page = oldQuery.page || 1;
        this.options.itemsPerPage = oldQuery.limit || 15
        this.options.sortBy = [oldQuery.sort || "createdAt"]
        this.options.sortDesc = [oldQuery.order || true]
        this.options.search = oldQuery.search || ""
        this.options.selectedBranches = oldQuery.selectedBranches || []
        this.options.status = (oldQuery.status || oldQuery.status===0)?oldQuery.status:null

        if(this.options.selectedBranches.length>0) this.allBranchesSelected = false;

        //used for pagination end
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error.msg?.message || error, "error");
      }
    },
    async mounted(){
        try {
          this.init = true;

          this.pagination.pageSizeOptions = this.getGlobalValue("paginationPageLimitsGeneral")?.split(",").map(x => parseInt(x)) || this.pagination.pageSizeOptions;

          if(this.isAllowed('dispatch', 'excludeBranchFilter')){
            this.allBranches = this.getBranches.map(x => {
              return {
                label: x.name,
                value: x.id
              }
            });
          }
          else{
            this.allBranches = this.getUser?.metadata?.branchData?.allowed.map(x => {
              return {
                label: this.lookupBranch(x),
                value: x
              };
            });
          }

          await this.getAllData();
          this.init = false;
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
            this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getId', 'getEndpoint', 'lookupUsername', 'getGlobalValue', 'lookupBranch', 'getBranches', 'isAllowed', 'getUser', 'serialNaming']),
    },
    watch: {
      options: {
        async handler () {
          await this.getAllData();
        },
        deep: true,
      },
      $route: {
        handler: async function (current) {
          //used for pagination
          if (this.updatingRoute) return;
          let oldQuery = this.getURLQuery(current.query);
          this.options.page = oldQuery.page || 1;
          this.options.itemsPerPage = oldQuery.limit || 15
          this.options.sortBy = [oldQuery.sort || "createdAt"]
          this.options.sortDesc = [oldQuery.order || true]
          this.options.search = oldQuery.search || ""
          this.options.selectedBranches = oldQuery.selectedBranches || []
          this.options.status = (oldQuery.status || oldQuery.status===0)?oldQuery.status:null
          //used for pagination end include full watcher
        },
        deep: true
      }
    },
    methods: {
      getURLQuery(custom=null){
        let oldQuery = custom? {...custom}: {...this.$route.query};
        if(oldQuery.limit) oldQuery.limit = parseInt(oldQuery.limit);
        if(oldQuery.page) oldQuery.page = parseInt(oldQuery.page);
        if(oldQuery.order) oldQuery.order = oldQuery.order==='true';
        if(oldQuery.selectedBranches){
          if(Array.isArray(oldQuery.selectedBranches)) oldQuery.selectedBranches = oldQuery.selectedBranches.map(x => parseInt(x))
          else oldQuery.selectedBranches = [parseInt(oldQuery.selectedBranches)]
        }
        if(oldQuery.status) oldQuery.status = oldQuery.status || oldQuery.status===0?parseInt(oldQuery.status):null;
        return oldQuery;
      },
      async markCompleted(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/dispatch/markCompleted/${this.serialDialog.data.id}/${this.serialDialog.activeArea.id}`)
          if(res.data.error) throw res.data.error
          this.snack("Dispatch Marked as Collected.")
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },   
      async markSentOut(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/dispatch/markSentOut/${this.serialDialog.data.id}/${this.serialDialog.activeArea.id}`)
          if(res.data.error) throw res.data.error
          this.snack("Dispatch Marked as Sent Out.")
        } catch (error) {
          console.log(error)
          this.snack(error)
        }
      },
      async getAllData(){
        try{
          //used for pagination
          this.pagination.loading = true;
          let paginationData = {
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sort: (this.options.sortBy.length>0)?this.options.sortBy[0]:"id",
            order: false, //this.options.sortDesc[0],
            search: this.options.search,
            selectedBranches: this.options.selectedBranches,
            status: this.options.status
          }
          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/dispatch/tableDataPaginated?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.orders = res.data.data.map(x => {
            return {
              ...x,
              isLoading: false
            }
          })

          //used for pagination
          this.pagination.dataCount = res.data.total
          let shouldUpdate = false;
          let oldQuery = this.getURLQuery();
          let newQuery = {...paginationData};

          if(Object.keys(newQuery).length!==Object.keys(oldQuery).length) shouldUpdate = true;
          if(!shouldUpdate){
            for(let key of Object.keys(newQuery)){
              if(shouldUpdate) break;

              if(Array.isArray(newQuery[key])){
                if(newQuery[key].length!==oldQuery[key].length){
                  shouldUpdate = true;
                  break;
                }
                for(let i=0;i<newQuery[key].length-1;i++){
                  if(newQuery[key][i]!==oldQuery[key][i]){
                    shouldUpdate = true;
                    break;
                  }
                }
              }
              else if(newQuery[key]!==oldQuery[key]){
                shouldUpdate = true;
              }
            }
          }

          if(shouldUpdate){
            this.updatingRoute = true;
            if(this.init){
              if(this.$route.path===`/dispatch`) await this.$router.replace({path: `/dispatch`, query: newQuery});
            }
            else{
              if(this.$route.path===`/dispatch`) await this.$router.push({path: `/dispatch`, query: newQuery});
            }
            this.updatingRoute = false;
          }
          //used for pagination end
        } catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.pagination.loading = false;
          this.loader = false;
        }
      },
      updateBranchFilter(id){
        if(id===0){
          if(this.allBranchesSelected){ //the value is already being set true by the component
            this.options.selectedBranches = [];
          }
          else{
            this.options.selectedBranches = this.allBranches.map(x => x.value);
          }
        }
        else{
          if(this.options.selectedBranches.length===0) this.allBranchesSelected = true;
          else this.allBranchesSelected = false;
        }
      },
      async searchSerial(){
        try{
          this.loader = true
          let res = await axios.post(`${this.getEndpoint}/api/${this.singularLower}/searchSerial`, {query: this.serialSearch})
          if(res.data.error) throw res.data.error
          this.serialSearchResults = res.data.data
        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      goToDocument(){
        try{
          if(!this.dispatchIdSearch?.trim()) return;

          this.goToDocumentLoader = true

          if(isNaN(this.dispatchIdSearch) || !Number.isInteger(parseInt(this.dispatchIdSearch))) throw "Invalid Dispatch ID";

          this.openSerialDialog({id: this.dispatchIdSearch});

        } catch (error) {
          console.log(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.goToDocumentLoader = false;
        }
      },
      rowClick(row){
        this.$router.push({ path: `/dispatch/view/${row.id}`})
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
    }
  }
</script>
